var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("br"),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c(
      "div",
      { staticClass: "q-gutter-y-md", staticStyle: { width: "100%" } },
      [
        _c(
          "q-card",
          { attrs: { flat: "" } },
          [
            _c(
              "q-tabs",
              {
                attrs: { align: "center", "narrow-indicator": "" },
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              [
                _c("q-tab", {
                  staticClass: "text-weight-bolder",
                  attrs: { name: "generali", label: "Informazioni generali" },
                }),
                _c("q-tab", {
                  staticClass: "text-weight-bolder",
                  attrs: {
                    name: "questionari",
                    label: "Analisi necessita / Analisi Rischio",
                  },
                }),
                _c("q-tab", {
                  staticClass: "text-weight-bolder",
                  attrs: {
                    name: "questionari_assuntivi",
                    label: "Questionari assuntivi",
                  },
                }),
                _c("q-tab", {
                  staticClass: "text-weight-bolder",
                  attrs: { name: "cliente", label: "Dati del Cliente" },
                }),
                _c("q-tab", {
                  staticClass: "text-weight-bolder",
                  attrs: { name: "contratti", label: "Contratti" },
                }),
                _c("q-tab", {
                  staticClass: "text-weight-bolder",
                  attrs: { name: "documenti", label: "Documenti" },
                }),
              ],
              1
            ),
            _c(
              "q-tab-panels",
              {
                ref: "tabs",
                attrs: {
                  animated: "",
                  "transition-prev": "jump-down",
                  "transition-next": "jump-down",
                },
                model: {
                  value: _vm.tab,
                  callback: function ($$v) {
                    _vm.tab = $$v
                  },
                  expression: "tab",
                },
              },
              [
                _c("q-tab-panel", { attrs: { name: "generali" } }, [
                  _c("table", { staticClass: "table" }, [
                    _c("tbody", [
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Numero: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.getNumeroPratica)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Data di creazione: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.getDataCreazionePratica)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Data di decorrenza richiesta: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.getDataDecorrenzaPratica)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Identificativo unità operativa: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.getIDUnitaOperativa)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Stato della pratica: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.getStatoPratica)),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", { staticClass: "domanda" }, [
                          _vm._v("Note: "),
                        ]),
                        _c("td", { staticClass: "risposta" }, [
                          _vm._v(_vm._s(_vm.annotazione)),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("q-tab-panel", { attrs: { name: "cliente" } }, [
                  _c("div", { staticClass: "row justify-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-md-5",
                        staticStyle: { padding: "8px" },
                      },
                      [
                        _c("fieldset", [
                          _c("legend", [
                            _c("strong", [_vm._v("Dati anagrafici")]),
                          ]),
                          _c("table", { staticClass: "table" }, [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Nominativo/Ragione Sociale"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getNominativoCliente)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Professione"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getProfessioneCliente)),
                                ]),
                              ]),
                              _vm.isPersonaFisica
                                ? _c("tr", [
                                    _c("td", { staticClass: "domanda" }, [
                                      _vm._v("Codice Fiscale"),
                                    ]),
                                    _c("td", { staticClass: "risposta" }, [
                                      _vm._v(
                                        _vm._s(_vm.getCodiceFiscaleCliente)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              !_vm.isPersonaFisica
                                ? _c("tr", [
                                    _c("td", { staticClass: "domanda" }, [
                                      _vm._v("Partita IVA"),
                                    ]),
                                    _c("td", { staticClass: "risposta" }, [
                                      _vm._v(_vm._s(_vm.getPartitaIvaCliente)),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.isPersonaFisica
                                ? _c("tr", [
                                    _c("td", { staticClass: "domanda" }, [
                                      _vm._v("Sesso"),
                                    ]),
                                    _c("td", { staticClass: "risposta" }, [
                                      _vm._v(_vm._s(_vm.getSessoCliente)),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.isPersonaFisica
                                ? _c("tr", [
                                    _c("td", { staticClass: "domanda" }, [
                                      _vm._v("Data di nascita"),
                                    ]),
                                    _c("td", { staticClass: "risposta" }, [
                                      _vm._v(_vm._s(_vm.getDataNascitaCliente)),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.isPersonaFisica
                                ? _c("tr", [
                                    _c("td", { staticClass: "domanda" }, [
                                      _vm._v("Comune di nascita"),
                                    ]),
                                    _c("td", { staticClass: "risposta" }, [
                                      _vm._v(
                                        _vm._s(_vm.getComuneNascitaCliente)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm.isPersonaFisica
                                ? _c("tr", [
                                    _c("td", { staticClass: "domanda" }, [
                                      _vm._v("Provincia di nascita"),
                                    ]),
                                    _c("td", { staticClass: "risposta" }, [
                                      _vm._v(
                                        _vm._s(_vm.getProvinciaNascitaCliente)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                        _c("br"),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-md-5",
                        staticStyle: { padding: "8px" },
                      },
                      [
                        _c("fieldset", [
                          _c("legend", [
                            _c("strong", [_vm._v("Residenza / Sede legale")]),
                          ]),
                          _c("table", { staticClass: "table" }, [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Indirizzo"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getIndirizzoResidenza)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Numero civico"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getCivicoResidenza)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Comune"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getComuneResidenza)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Cap"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getCapResidenza)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Provincia"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getProvinciaResidenza)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("br"),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-md-5",
                        staticStyle: { padding: "8px" },
                      },
                      [
                        _c("fieldset", [
                          _c("legend", [
                            _c("strong", [_vm._v("Corrispondenza")]),
                          ]),
                          _c("table", { staticClass: "table" }, [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Indirizzo"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(_vm.getIndirizzoCorrispondenza)
                                  ),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Numero civico"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getCivicoCorrispondenza)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Comune"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getComuneCorrispondenza)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Cap"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getCapCorrispondenza)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Provincia"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(
                                    _vm._s(_vm.getProvinciaCorrispondenza)
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("br"),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-md-5",
                        staticStyle: { padding: "8px" },
                      },
                      [
                        _c("fieldset", [
                          _c("legend", [_c("strong", [_vm._v("Recapiti")])]),
                          _c("table", { staticClass: "table" }, [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Telefono fisso"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getTelefonoCliente)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Cellulare"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getCellulareCliente)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Email"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getEmailCliente)),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v("Email PEC"),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.getPecCliente)),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm.dati_caricati_dal_backend
                  ? _c("q-tab-panel", { attrs: { name: "contratti" } }, [
                      _c("table", { staticClass: "table-bordered" }, [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { attrs: { rowspan: "2" } }, [
                              _vm._v("Prodotto"),
                            ]),
                            _c("th", { attrs: { colspan: "2" } }, [
                              _vm._v("Compagnia"),
                            ]),
                            _c("th", { attrs: { rowspan: "2" } }, [
                              _vm._v("Frazionamento"),
                            ]),
                            _c("th", { attrs: { rowspan: "2" } }, [
                              _vm._v("Stato"),
                            ]),
                            _c("th", { attrs: { colspan: "3" } }, [
                              _vm._v("Premio rata"),
                            ]),
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("Ragione Sociale")]),
                            _c("th", [_vm._v("Fornitore")]),
                            _c("th", [_vm._v("Premio Polizza")]),
                            _c("th", [_vm._v("Premio Acquisto")]),
                            _c("th", [_vm._v("Provvigioni")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.prodotti_selezionati.contratti,
                            function (contratto, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm._v(_vm._s(contratto.label_prodotto)),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(contratto.compagnia.toUpperCase())
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(contratto.fornitore))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getFrazionamentoContratto(contratto)
                                    )
                                  ),
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getStatoPratica.replaceAll("_", " ")
                                    )
                                  ),
                                ]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getPremioPolizza(
                                        contratto.compagnia,
                                        contratto.tariffa
                                      )
                                    ) + " Euro"
                                  ),
                                ]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getPremioContratto(
                                        contratto.compagnia,
                                        contratto.tariffa
                                      )
                                    ) + " Euro"
                                  ),
                                ]),
                                _c("td", { attrs: { align: "right" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getProvvigioniContratto(
                                        contratto.compagnia,
                                        contratto.tariffa
                                      )
                                    ) + " Euro"
                                  ),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                        _c("tfoot", [
                          _c("tr", [
                            _c(
                              "td",
                              { attrs: { colspan: "6", align: "right" } },
                              [_c("strong", [_vm._v("TOTALE")])]
                            ),
                            _c("td", { attrs: { align: "right" } }, [
                              _vm._v(_vm._s(_vm.getPremioTotale()) + " Euro"),
                            ]),
                            _c("td", { attrs: { align: "right" } }, [
                              _vm._v(
                                _vm._s(_vm.getProvvigioniTotale()) + " Euro "
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("br"),
                      _c("br"),
                      _c(
                        "div",
                        { attrs: { align: "center" } },
                        _vm._l(
                          _vm.prodotti_selezionati.contratti,
                          function (contratto, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticStyle: { "max-width": "800px" },
                                attrs: { align: "left" },
                              },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass: "q-mb-sm q-mt-sm",
                                    staticStyle: {
                                      border: "1px SOLID #c0c0c0",
                                      "background-color": "#ffcc99",
                                      color: "#000",
                                    },
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(contratto.label_prodotto) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "q-list",
                                  { attrs: { separator: "" } },
                                  [
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("q-item-label", [
                                              _vm._v("Compagnia"),
                                            ]),
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    contratto.compagnia.toUpperCase()
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("q-item-label", [
                                              _vm._v("Frazionamento"),
                                            ]),
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    contratto.frazionamento.toUpperCase()
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("q-item-label", [
                                              _vm._v(
                                                "Numero Polizza Compagnia"
                                              ),
                                            ]),
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    contratto.numero_polizza
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "q-item",
                                      [
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("q-item-label", [
                                              _vm._v("Decorrenza"),
                                            ]),
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  _vm._s(contratto.decorrenza)
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-section",
                                          [
                                            _c("q-item-label", [
                                              _vm._v("Scadenza"),
                                            ]),
                                            _c(
                                              "q-item-label",
                                              { attrs: { caption: "" } },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contratto.scadenza) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-item-label",
                                          [
                                            _c("q-item-label", [
                                              _vm._v("Durata Anni"),
                                            ]),
                                            _c(
                                              "q-item-label",
                                              {
                                                attrs: {
                                                  caption: "",
                                                  align: "center",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      contratto.durata_anni
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._l(
                                      contratto.parametri,
                                      function (parametro, indexParametri) {
                                        return _c(
                                          "q-item",
                                          { key: indexParametri },
                                          [
                                            _c(
                                              "q-item-section",
                                              [
                                                _c("q-item-label", [
                                                  _vm._v(
                                                    _vm._s(parametro.label)
                                                  ),
                                                ]),
                                                _c(
                                                  "q-item-label",
                                                  { attrs: { caption: "" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.analizzaRisposta(
                                                          parametro
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
                _c("q-tab-panel", { attrs: { name: "questionari" } }, [
                  _c("div", { staticClass: "row justify-center" }, [
                    _c("div", { staticClass: "col-12 col-md-10" }, [
                      _c("fieldset", [
                        _c("legend", [
                          _c("strong", [_vm._v("Analisi necessità")]),
                        ]),
                        _c("table", { staticClass: "table" }, [
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.getDomandeAnalisiNecessita,
                              function (domanda, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", { staticClass: "domanda" }, [
                                    _vm._v(_vm._s(domanda.label) + " "),
                                  ]),
                                  _c("td", { staticClass: "risposta" }, [
                                    _vm._v(
                                      _vm._s(_vm.analizzaRisposta(domanda))
                                    ),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                      _c("br"),
                      _c(
                        "fieldset",
                        [
                          _c("legend", [
                            _c("strong", [_vm._v("Analisi rischio")]),
                          ]),
                          _vm._l(
                            _vm.getDomandeAnalisiRischio,
                            function (sezione_domanda, index) {
                              return _c("div", { key: index }, [
                                _c(
                                  "h5",
                                  {
                                    staticClass: "q-mb-sm q-mt-sm",
                                    staticStyle: {
                                      border: "1px SOLID #c0c0c0",
                                    },
                                    attrs: { align: "center" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(sezione_domanda.label) + " "
                                    ),
                                  ]
                                ),
                                _c("table", { staticClass: "table" }, [
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      sezione_domanda.domande.filter(function (
                                        d
                                      ) {
                                        return d.value !== ""
                                      }),
                                      function (domanda, indexDomanda) {
                                        return _c("tr", { key: indexDomanda }, [
                                          _c("td", {
                                            staticClass: "domanda",
                                            domProps: {
                                              innerHTML: _vm._s(domanda.label),
                                            },
                                          }),
                                          _c(
                                            "td",
                                            { staticClass: "risposta" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.analizzaRisposta(domanda)
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "q-tab-panel",
                  { attrs: { name: "questionari_assuntivi" } },
                  [
                    _c("div", { staticClass: "row justify-center" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-10" },
                        [
                          _c(
                            "q-tabs",
                            {
                              attrs: {
                                align: "center",
                                "narrow-indicator": "",
                              },
                              model: {
                                value: _vm.tab_questionari,
                                callback: function ($$v) {
                                  _vm.tab_questionari = $$v
                                },
                                expression: "tab_questionari",
                              },
                            },
                            _vm._l(
                              _vm.questionari_assuntivi,
                              function (questionario, index) {
                                return _c("q-tab", {
                                  key: index,
                                  staticClass: "text-weight-bolder",
                                  attrs: { name: index, label: index },
                                })
                              }
                            ),
                            1
                          ),
                          _c(
                            "q-tab-panels",
                            {
                              ref: "tabs",
                              attrs: {
                                animated: "",
                                "transition-prev": "jump-down",
                                "transition-next": "jump-down",
                              },
                              model: {
                                value: _vm.tab_questionari,
                                callback: function ($$v) {
                                  _vm.tab_questionari = $$v
                                },
                                expression: "tab_questionari",
                              },
                            },
                            _vm._l(
                              _vm.questionari_assuntivi,
                              function (
                                questionario,
                                index,
                                index_questionario
                              ) {
                                return _c(
                                  "q-tab-panel",
                                  { key: index, attrs: { name: index } },
                                  [
                                    _vm.isRcTecnico(questionario)
                                      ? _c(
                                          "QQQuestionarioTecnicoDaIDPreventivo",
                                          {
                                            attrs: {
                                              indice_questionario_rischio:
                                                index_questionario,
                                              guid_preventivo: _vm.IDPratica,
                                            },
                                          }
                                        )
                                      : _vm._e(),
                                    _vm.isAltroProdotto(questionario)
                                      ? _c(
                                          "QQQuestionarioPersoneAziendeDaIDPreventivo",
                                          {
                                            attrs: {
                                              indice_questionario_rischio:
                                                index_questionario,
                                              guid_preventivo: _vm.IDPratica,
                                            },
                                          }
                                        )
                                      : _vm._e(),
                                    _vm.isRcSanitario(questionario)
                                      ? _c(
                                          "QQQuestionarioSanitarioDaIDPreventivo",
                                          {
                                            attrs: {
                                              indice_questionario_rischio:
                                                index_questionario,
                                              guid_preventivo: _vm.IDPratica,
                                            },
                                          }
                                        )
                                      : _vm._e(),
                                    _vm.isRcMedico(questionario)
                                      ? _c(
                                          "QQQuestionarioMedicoDaIDPreventivo",
                                          {
                                            attrs: {
                                              indice_questionario_rischio:
                                                index_questionario,
                                              guid_preventivo: _vm.IDPratica,
                                            },
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _c(
                  "q-tab-panel",
                  { attrs: { name: "documenti", align: "center" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "shadow-12",
                        staticStyle: {
                          "max-width": "1000px",
                          border: "1px SOLID #000",
                        },
                      },
                      [
                        _c("QQElencoDocumentiPratica", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.dati_caricati_dal_backend,
                              expression: "dati_caricati_dal_backend",
                            },
                          ],
                          attrs: {
                            align: "left",
                            title: "",
                            tabIndex: "0",
                            showContratti: "",
                            id_pratica: _vm.IDPratica,
                            formPratica: _vm.formPratica,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { attrs: { align: "center" } },
                      [
                        _c("h5", [_vm._v("Allega altri documenti")]),
                        _c("q-uploader", {
                          ref: "UploadObjectScheda",
                          staticStyle: {
                            "max-width": "800px",
                            width: "100%",
                            height: "300px",
                            "box-shadow":
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                          },
                          attrs: {
                            url: "https://localhost:1000",
                            color: "teal",
                            label:
                              "Trascina QUI i nuovi documenti da allegare alla pratica",
                            multiple: "",
                            "hide-upload-btn": "",
                            filter: _vm.checkFileType,
                          },
                          on: {
                            rejected: _vm.onRejected,
                            added: _vm.onAggiungiFileAllegati,
                            removed: _vm.onRimuoviFileAllegati,
                            uploading: _vm.onUploading,
                          },
                        }),
                        _c("br"),
                        _c("QQButton", {
                          attrs: {
                            label: "Upload Files",
                            color: "blue-grey",
                            icon: "save",
                            size: "md",
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onUploading.apply(null, arguments)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "Torna al Menu",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onIndietroClicked.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [_vm._v("Scheda della pratica")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }