var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.questionario_caricato
    ? _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-12 col-md-10", attrs: { align: "left" } },
          [
            _vm._m(0),
            _vm._m(1),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Attività svolta"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(_vm.getRispostaQuestionariFromName("lavoro"))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Tipo di inquadramento"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .getRispostaQuestionariFromName(
                                  "tipologia_attivita_persona"
                                )
                                .replaceAll("_", " ")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v("Specializzazione"),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getRispostaQuestionariFromName(
                                "specializzazione"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Sei un professionista iscritto ad un albo e/o abilitato all'esercizio dell'attivita` professionale?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getRispostaQuestionariFromName(
                                "iscrizione_albo"
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda("data_inizio_attivita").label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda("data_inizio_attivita").value
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "data_ultimo_aggiornamento_prof"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "data_ultimo_aggiornamento_prof"
                              ).value
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _vm._m(2),
            _c(
              "q-list",
              { attrs: { separator: "" } },
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c(
                          "q-item-label",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isTaylorMade,
                                expression: "!isTaylorMade",
                              },
                            ],
                            attrs: { caption: "" },
                          },
                          [_vm._v("Compagnia")]
                        ),
                        _c(
                          "q-item-label",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isTaylorMade,
                                expression: "!isTaylorMade",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.getCompagniaSelezionata) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item-section",
                      [
                        _c(
                          "q-item-label",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isTaylorMade,
                                expression: "!isTaylorMade",
                              },
                            ],
                            attrs: { caption: "" },
                          },
                          [_vm._v("Attività per specializzazione")]
                        ),
                        _c(
                          "q-list",
                          _vm._l(
                            _vm.attivita_aggiuntive_da_analisi_rischio,
                            function (garanzia, index) {
                              return _c(
                                "q-item",
                                { key: index },
                                [
                                  _c(
                                    "q-item-section",
                                    [
                                      _c(
                                        "q-item-label",
                                        [
                                          _c("q-checkbox", {
                                            attrs: {
                                              label: garanzia.label,
                                              disabled: "",
                                            },
                                            model: {
                                              value: _vm.checked,
                                              callback: function ($$v) {
                                                _vm.checked = $$v
                                              },
                                              expression: "checked",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._l(
                  _vm.getElencoGaranzieAggiuntive,
                  function (garanzia, index) {
                    return _c(
                      "q-item",
                      { key: index },
                      [
                        _c(
                          "q-item-section",
                          [
                            _c(
                              "q-item-label",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isTaylorMade,
                                    expression: "!isTaylorMade",
                                  },
                                ],
                                attrs: { caption: "" },
                              },
                              [_vm._v(_vm._s(garanzia.label))]
                            ),
                            _c(
                              "q-item-label",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.isTaylorMade,
                                    expression: "!isTaylorMade",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.analizzaRisposta(garanzia)) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            ),
            _c("br"),
            _vm._m(3),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Hai mai stipulato polizze per questo rischio?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            " " + _vm._s(_vm.havePolizzaStessoRischio) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.havePolizzaStessoRischio === "SI",
                        expression: "havePolizzaStessoRischio === 'SI' ",
                      },
                    ],
                  },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v("Impresa di assicurazione"),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getRispostaQuestionariFromName(
                                    "compagnia"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v("Data di scadenza della copertura"),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getRispostaQuestionariFromName(
                                    "scadenza_copertura"
                                  )
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c(
                              "q-item-label",
                              [
                                _c("q-item-label", { attrs: { caption: "" } }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getObjectDomanda(
                                        "copertura_assicurativa_precedente.nome_assicuratore"
                                      ).label
                                    )
                                  ),
                                ]),
                                _c("q-item-label", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getObjectDomanda(
                                        "copertura_assicurativa_precedente.nome_assicuratore"
                                      ).value
                                    ) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.massimale"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.massimale"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.franchigie_scoperti"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.franchigie_scoperti"
                                  ).value
                                ) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.premio_lordo"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.premio_lordo"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.retroattivita"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "copertura_assicurativa_precedente.retroattivita"
                                  ).value
                                ) + " "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Qualche Compagnia assicuratrice ha mai annullato o rifiutato di concedere o rinunciato a rinnovare la copertura negli ultimi 5 anni?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(_vm._s(_vm.PolizzaSchifataDaCompagnia)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _vm.PolizzaSchifataDaCompagnia === "SI"
                      ? _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "motivo_rifiuto_compagnia"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "motivo_rifiuto_compagnia"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _vm._m(4),
            _c(
              "q-list",
              [
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Hai mai ricevuto richieste di risarcimento inerenti l`attività professionale?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            " " + _vm._s(_vm.haveRichiesteRisarcimento) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.haveRichiesteRisarcimento === "SI",
                        expression: "haveRichiesteRisarcimento === 'SI'",
                      },
                    ],
                  },
                  [
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.data_richiesta_risarcimento"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.data_richiesta_risarcimento"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.periodo_contestazione"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.periodo_contestazione"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_richiesta_danni"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_richiesta_danni"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_sinistro_pagato"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.valore_sinistro_pagato"
                                  ).value
                                ) + " Euro"
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_transazione"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_transazione"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_sentenza_civile"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.pagamento_con_sentenza_civile"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "q-item",
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", { attrs: { caption: "" } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.descrizione_fatti"
                                  ).label
                                )
                              ),
                            ]),
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.getObjectDomanda(
                                    "sinistri.descrizione_fatti"
                                  ).value
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            "Sei a conoscenza di circostanze che possono portare ad una richiesta di risarcimento da parte di clienti?"
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.haveConoscenzaPossibiliRichiesteRisarcimento
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "q-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.haveConoscenzaPossibiliRichiesteRisarcimento ===
                          "SI",
                        expression:
                          "haveConoscenzaPossibiliRichiesteRisarcimento === 'SI'",
                      },
                    ],
                  },
                  [
                    _c(
                      "q-item-section",
                      [
                        _c("q-item-label", { attrs: { caption: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "sinistri.descrizione_possibili_richieste_danni"
                              ).label
                            )
                          ),
                        ]),
                        _c("q-item-label", [
                          _vm._v(
                            _vm._s(
                              _vm.getObjectDomanda(
                                "sinistri.descrizione_possibili_richieste_danni"
                              ).value
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Informazioni generali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Proponente - Dati professionali")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Caratteristiche generali")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("strong", [_vm._v("Precedenti assicurazioni per questo rischio")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [_c("strong", [_vm._v("Informazioni sui sinistri")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }