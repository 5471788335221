import { render, staticRenderFns } from "./SchedaDellaPratica.vue?vue&type=template&id=497909ab&scoped=true&"
import script from "./SchedaDellaPratica.vue?vue&type=script&lang=js&"
export * from "./SchedaDellaPratica.vue?vue&type=script&lang=js&"
import style0 from "./SchedaDellaPratica.vue?vue&type=style&index=0&id=497909ab&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "497909ab",
  null
  
)


import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QUploader from 'quasar/src/components/uploader/QUploader.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QTabs,QTab,QTabPanels,QTabPanel,QList,QItem,QItemSection,QItemLabel,QUploader});
/* hot reload */
if (module.hot) {
  var api = require("/builds/navert/abynext-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('497909ab')) {
      api.createRecord('497909ab', component.options)
    } else {
      api.reload('497909ab', component.options)
    }
    module.hot.accept("./SchedaDellaPratica.vue?vue&type=template&id=497909ab&scoped=true&", function () {
      api.rerender('497909ab', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/PiattaformaRami/Pratiche/SchedaDellaPratica.vue"
export default component.exports